<template>
    <div class="main-page">
        <section class="page-section mb-2">
            <div class="container">
                <div class="grid justify-content-center">
                    <div class="col-12 md:col-7 comp-grid">
                        <template v-if="!loading">
                            <div v-if="formData.email_status != received" class="card nice-shadow-18">
                                <div class="my-3">
                                    <div class="text-lg font-bold text-center text-primary">Recommendation for {{
                                        formData.applicant_name }}</div>
                                </div>
                                <Divider></Divider>
                                <div class="grid">
                                    <div class="col-12">
                                        <form ref="observer" tag="form" @submit.prevent="startRecordUpdate()">

                                            <div class="card nice-shadow-1 mt-2 py-3">
                                                <div class="font-bold text-primary text-md">
                                                    {{ $t("Referee Information") }}
                                                </div>
                                            </div>
                                            <div class="card nice-shadow-2 mt-2">
                                                <div class="grid">
                                                    <div class="col-12 md:col-6">
                                                        <div class="mb-2 font-bold text-sm">
                                                            {{ $t("first_name") }} *
                                                        </div>
                                                        <div class="">
                                                            <InputText ref="ctrlfirstname"
                                                                v-model.trim="formData.referee_firstname"
                                                                :label="$t('first_name')" type="text"
                                                                :placeholder="$t('first_name')" class="w-full"
                                                                :class="getErrorClass('referee_firstname')">
                                                            </InputText>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 md:col-6">
                                                        <div class="mb-2 font-bold text-sm">
                                                            {{ $t("last_name") }} *
                                                        </div>
                                                        <div class="">
                                                            <InputText ref="ctrllastname"
                                                                v-model.trim="formData.referee_lastname"
                                                                :label="$t('last_name')" type="text"
                                                                :placeholder="$t('last_name')" class="w-full"
                                                                :class="getErrorClass('referee_lastname')">
                                                            </InputText>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 md:col-12">
                                                        <div class="mb-2 font-bold text-sm">
                                                            {{ $t("email_address") }} *
                                                        </div>
                                                        <div class="">
                                                            <InputText ref="ctrlemail_address"
                                                                v-model.trim="formData.referee_email"
                                                                :label="$t('email_address')" type="email"
                                                                :placeholder="$t('email_address')" class="w-full"
                                                                maxlength="40" :class="getErrorClass('referee_email')">
                                                            </InputText>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 md:col-6">
                                                        <div class="mb-2 font-bold text-sm">
                                                            {{ $t("Phone Number") }} *
                                                        </div>
                                                        <div class="">
                                                            <InputText ref="ctrltelephone"
                                                                v-model.trim="formData.referee_phone"
                                                                :label="$t('Phone Number')" type="text"
                                                                :placeholder="$t('Phone Number')" pattern="^[0-9]+$"
                                                                class="w-full" maxlength="12" minlength="8"
                                                                :class="getErrorClass('referee_phone')"
                                                                v-tooltip.top.focus="'Eg: 0209013836'">
                                                            </InputText>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 md:col-6">
                                                        <div class="mb-2 font-bold text-sm">
                                                            {{ $t("position") }} *
                                                        </div>
                                                        <div class="">
                                                            <InputText ref="ctrlposition"
                                                                v-model="formData.referee_position" :label="$t('position')"
                                                                type="text" :placeholder="$t('position')" class="w-full"
                                                                :class="getErrorClass('referee_position')">
                                                            </InputText>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 md:col-12">
                                                        <div class="mb-2 font-bold text-sm">
                                                            {{ $t("institution_or_organisation") }} *
                                                        </div>
                                                        <div class="">
                                                            <InputText ref="ctrlinstitution"
                                                                v-model="formData.referee_institution"
                                                                :label="$t('institution_or_organisation')" type="text"
                                                                :placeholder="$t('institution_or_organisation')" class="w-full"
                                                                :class="getErrorClass('referee_institution')">
                                                            </InputText>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 md:col-12">
                                                        <div class="mb-2 font-bold text-sm">
                                                            {{ $t("Relationship to the applicant") }} *
                                                        </div>
                                                        <div class="">
                                                            <InputText ref="ctrlreferee_relation"
                                                                v-model="formData.referee_relation"
                                                                :label="$t('referee_relation')" type="text"
                                                                :placeholder="$t('Academic or professional references preferred')" class="w-full"
                                                                :class="getErrorClass('referee_relation')">
                                                            </InputText>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 md:col-6">
                                                        <div class="mb-3 font-bold text-sm">
                                                            {{ $t("How long have you known the applicant?") }} *
                                                        </div>
                                                        <div class="">
                                                            <Dropdown class="w-full"
                                                                :class="getErrorClass('referee_known_years')"
                                                                ref="ctrlreferee_known_years" optionLabel="label"
                                                                optionValue="value" v-model="formData.referee_known_years"
                                                                :options="$menus.workingExperienceDurationItems"
                                                                :label="$t('referee_known_years')"
                                                                :placeholder="$t('select')">
                                                            </Dropdown>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="card nice-shadow-1 mt-2 py-3">
                                                <div class="font-bold text-primary text-md">
                                                    {{ $t("Rating") }}
                                                </div>
                                            </div>
                                            <div class="card nice-shadow-2 mt-2">
                                                <div class="text-sm mt-2">
                                                    <div>Please rate applicant's qualification for graduate study
                                                        considering the
                                                        following areas:</div>
                                                    <ul>
                                                        <li>1 - Must Improve</li>
                                                        <li>2 - Satisfactory</li>
                                                        <li>3 - Good</li>
                                                        <li>4 - Very Good</li>
                                                        <li>5 - Excellent</li>
                                                    </ul>
                                                </div>
                                                <div class="grid">
                                                    <Divider></Divider>
                                                    <div class="col-12 md:col-12">
                                                        <div class="grid">
                                                            <div class="col-12 md:col-7">Performance in independent
                                                                study or
                                                                in research groups</div>
                                                            <div class="col">
                                                                <div class="flex flex-wrap gap-3">
                                                                    <div class="flex align-items-center"
                                                                        v-for="option of $menus.ratingItems"
                                                                        :key="option.value">
                                                                        <div class="text-center">
                                                                            <RadioButton class="p-radiobutton-box"
                                                                                :id="option.value"
                                                                                :class="getErrorClass('rating')"
                                                                                name="performance_in_independent_study_or_in_research_groups"
                                                                                :value="option.value"
                                                                                v-model="formData.rating.performance_in_independent_study_or_in_research_groups" />
                                                                            <label :for="option.value"> {{
                                                                                option.label
                                                                                }}
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <Divider></Divider>
                                                    <div class="col-12 md:col-12">
                                                        <div class="grid">
                                                            <div class="col-12 md:col-7">Intellectual independence
                                                            </div>
                                                            <div class="col">
                                                                <div class="flex flex-wrap gap-3">
                                                                    <div class="flex align-items-center"
                                                                        v-for="option of $menus.ratingItems"
                                                                        :key="option.value">
                                                                        <div class="text-center">
                                                                            <RadioButton class="p-radiobutton-box"
                                                                                :id="option.value"
                                                                                name="intellectual_independence"
                                                                                :value="option.value"
                                                                                :class="getErrorClass('rating')"
                                                                                v-model="formData.rating.intellectual_independence" />
                                                                            <label :for="option.value"> {{
                                                                                option.label
                                                                                }}
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <Divider></Divider>
                                                    <div class="col-12 md:col-12">
                                                        <div class="grid">
                                                            <div class="col-12 md:col-7">Research interests</div>
                                                            <div class="col">
                                                                <div class="flex flex-wrap gap-3">
                                                                    <div class="flex align-items-center"
                                                                        v-for="option of $menus.ratingItems"
                                                                        :key="option.value">
                                                                        <div class="text-center">
                                                                            <RadioButton class="p-radiobutton-box"
                                                                                :id="option.value"
                                                                                name="research_interests"
                                                                                :value="option.value"
                                                                                :class="getErrorClass('rating')"
                                                                                v-model="formData.rating.research_interests" />
                                                                            <label :for="option.value"> {{
                                                                                option.label
                                                                                }}
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <Divider></Divider>
                                                    <div class="col-12 md:col-12">
                                                        <div class="grid">
                                                            <div class="col-12 md:col-7">Capacity for analytical
                                                                thinking</div>
                                                            <div class="col">
                                                                <div class="flex flex-wrap gap-3">
                                                                    <div class="flex align-items-center"
                                                                        v-for="option of $menus.ratingItems"
                                                                        :key="option.value">
                                                                        <div class="text-center">
                                                                            <RadioButton class="p-radiobutton-box"
                                                                                :id="option.value"
                                                                                name="capacity_for_analytical_thinking"
                                                                                :value="option.value"
                                                                                :class="getErrorClass('rating')"
                                                                                v-model="formData.rating.capacity_for_analytical_thinking" />
                                                                            <label :for="option.value"> {{
                                                                                option.label
                                                                                }}
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <Divider></Divider>
                                                    <div class="col-12 md:col-12">
                                                        <div class="grid">
                                                            <div class="col-12 md:col-7">Ability to work with others
                                                            </div>
                                                            <div class="col">
                                                                <div class="flex flex-wrap gap-3">
                                                                    <div class="flex align-items-center"
                                                                        v-for="option of $menus.ratingItems"
                                                                        :key="option.value">
                                                                        <div class="text-center">
                                                                            <RadioButton class="p-radiobutton-box"
                                                                                :id="option.value"
                                                                                name="ability_to_work_with_others"
                                                                                :value="option.value"
                                                                                :class="getErrorClass('rating')"
                                                                                v-model="formData.rating.ability_to_work_with_others" />
                                                                            <label :for="option.value"> {{
                                                                                option.label
                                                                                }}
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <Divider></Divider>
                                                    <div class="col-12 md:col-12">
                                                        <div class="grid">
                                                            <div class="col-12 md:col-7">Ability to organize and
                                                                express
                                                                ideas clearly</div>
                                                            <div class="col">
                                                                <div class="flex flex-wrap gap-3">
                                                                    <div class="flex align-items-center"
                                                                        v-for="option of $menus.ratingItems"
                                                                        :key="option.value">
                                                                        <div class="text-center">
                                                                            <RadioButton class="p-radiobutton-box"
                                                                                :id="option.value"
                                                                                name="ability_to_organize_and_express_ideas_clearly"
                                                                                :value="option.value"
                                                                                :class="getErrorClass('rating')"
                                                                                v-model="formData.rating.ability_to_organize_and_express_ideas_clearly" />
                                                                            <label :for="option.value"> {{
                                                                                option.label
                                                                                }}
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <Divider></Divider>
                                                    <div class="col-12 md:col-12">
                                                        <div class="grid">
                                                            <div class="col-12 md:col-7">Drive and motivation</div>
                                                            <div class="col">
                                                                <div class="flex flex-wrap gap-3">
                                                                    <div class="flex align-items-center"
                                                                        v-for="option of $menus.ratingItems"
                                                                        :key="option.value">
                                                                        <div class="text-center">
                                                                            <RadioButton class="p-radiobutton-box"
                                                                                :id="option.value"
                                                                                name="drive_and_motivation"
                                                                                :value="option.value"
                                                                                :class="getErrorClass('rating')"
                                                                                v-model="formData.rating.drive_and_motivation" />
                                                                            <label :for="option.value"> {{
                                                                                option.label
                                                                                }}
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <Divider></Divider>

                                                    <div class="col-12 md:col-12">
                                                        <div class="mb-2 font-bold text-sm">
                                                            Remark <i>(optional)</i>
                                                        </div>
                                                        <div class="">
                                                            <Textarea ref="ctrlremark" v-model.trim="formData.remark"
                                                                label="Remark" type="textarea"
                                                                placeholder="Type here ..." rows="3" class="w-full"
                                                                :class="getErrorClass('remark')"></Textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--[form-content-end]-->
                                            <div v-if="showSubmitButton" class="text-center my-3">
                                                <Button type="submit" label="Submit" icon="pi pi-send"
                                                    :loading="saving" />
                                            </div>
                                        </form>
                                        <slot :submit="submit" :saving="saving"></slot>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="card nice-shadow-18">
                                <div>
                                    <p>Your feedback has been saved in the system. You do not need to provide feedback
                                        for {{ formData.applicant_name }} again. </p>
                                    <p>We would like to thank you again for your kind help. If you have any questions,
                                        please
                                        don't hesitate to contact us at info@gtuc.edu.gh</p>
                                    <p>Thank You!</p>
                                    <p>Admissions Office</p>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <div class="p-3 text-center card nice-shadow-18">
                                <ProgressSpinner style="width: 30px; height: 30px" />
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
    import { PageMixin } from "../../mixins/page.js";
    import { EditPageMixin } from "../../mixins/editpage.js";
    import { mapActions, mapGetters, mapState } from "vuex";
    import useVuelidate from "@vuelidate/core";
    import { required } from "@vuelidate/validators";
    export default {
        setup() {
            return { v$: useVuelidate() };
        },
        name: "recommendationApplicantsPage",
        components: {},
        mixins: [PageMixin, EditPageMixin],
        props: {
            pageName: {
                type: String,
                default: "applicants",
            },
            idName: {
                type: String,
                default: "applicant_id",
            },
            routeName: {
                type: String,
                default: "applicantsrecommendation",
            },
            pagePath: {
                type: String,
                default: "applicants/recommendation",
            },
            apiPath: {
                type: String,
                default: "applicants/recommendation",
            },
            pageData: null,
        },
        data() {
            return {
                formData: {
                    referee_firstname: "",
                    referee_lastname: "",
                    referee_email: "",
                    referee_phone: "",
                    referee_institution: "",
                    referee_position: "",
                    referee_relation: "",
                    referee_known_years: "",
                    rating: {},
                    remark: "",
                },
                received: "Received",
                submitted: false,
            };
        },
        computed: {
            pageTitle: {
                get: function () {
                    return this.$t("Applicant Recommendation");
                },
            },
            currentRecord: {
                get: function () {
                    return this.$store.getters["applicants/currentRecord"];
                },
                set: function (value) {
                    this.$store.commit("applicants/setCurrentRecord", value);
                },
            },
        },
        validations() {
            let formData = {
                referee_firstname: { required },
                referee_lastname: { required },
                referee_email: { required },
                referee_phone: { required },
                referee_institution: { required },
                referee_position: { required },
                referee_relation: { required },
                referee_known_years: { required },
                remark: {  },
                rating: { required },
            };
            return { formData };
        },
        methods: {
            ...mapActions("applicants", ["updateRecord", "fetchRecord"]),
            async startRecordUpdate() {
                this.submitted = true;
                const isFormValid = !this.v$.$invalid;
                if (!isFormValid) {
                    this.flashMsg("Please complete the form", "Form is invalid", "error");
                    return;
                }
                this.saving = true;
                let id = this.id;
                let url = this.apiUrl;
                let payload = this.normalizedFormData();
                let data = { id, url, payload };
                this.updateRecord(data).then(
                    (response) => {
                        this.saving = false;
                        this.flashMsg(this.msgAfterUpdate);
                        this.resetForm();
                        this.closeDialogs(); // close page dialog that if opened
                        location.reload();
                    },
                    (response) => {
                        this.saving = false;
                        this.showPageRequestError(response);
                    }
                );
            },
            updateFormFields: function () {
                //update form fields value after load from api
                //e.g convert fieldvalue (value,value2,value2) to array
            },
            resetForm() {
                //reset form fields value
                //raise event to reset other custom form components
                //this.$EventBus.$emit("resetForm");
            },
            getErrorClass(field) {
                if (this.v$.formData[field].$invalid && this.submitted) {
                    return "p-invalid";
                }
                return "";
            },
        },
        watch: {},
        async mounted() { },
        async created() { },
    };
</script>
<style scoped></style>